import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="logo512.png" className="App-logo" alt="logo" />
        <p>
          En Construcción.!
        </p>
      </header>
    </div>
  );
}

export default App;
